<template>
  <div class="vh76Container w-full" ref="galleryContainer">
    <div v-if="galleryLoading" class="text-center w-full h-full py-52 sm:py-28 md:py-36 lg:py-52">
      <base-svg
        class="h-4 w-4 mr-1 text-primary-red inline-block"
        src="icons/circleSpinner.svg"
        tag="span"
      />
      Loading ...
    </div>
    <template v-else>
      <!-- render style guide categories if there is no node selected and assets list is null -->
      <gallery-categories v-if="!selectedNode && assetsList === null && !assetsLoading" />

      <!-- render style guide folders if a node is selected and assets list is null -->
      <gallery-folders v-else-if="selectedNode && assetsList === null && !assetsLoading" />

      <!-- render style guide assets if assets list is not empty or assets are loading -->
      <gallery-assets v-else class="h-full relative" />

    </template>
  </div>
</template>

<script>
import { defineAsyncComponent, computed } from 'vue';
import { useStore } from 'vuex';
import useStyleGuideDirectory from '@/hooks/styleGuideDirectory.js';
import useStyleGuideAssets from '@/hooks/styleGuideAssets.js';
import BaseSvg from '@/components/generic-components/BaseSvg.vue';

export default {
    name: 'StyleGuideGallery',

    components: {
        GalleryCategories: defineAsyncComponent(() => import('./GalleryCategories.vue')),
        GalleryFolders: defineAsyncComponent(() => import('./GalleryFolders.vue')),
        GalleryAssets: defineAsyncComponent(() => import('./GalleryAssets.vue')),
        BaseSvg
    },

    setup () {
        const store = useStore();
        const { selectedNode, nodeContentLoading } = useStyleGuideDirectory();
        const { assetsLoading } = useStyleGuideAssets();
        const galleryLoading = computed(() => nodeContentLoading.value);
        const assetsList = computed(() => store.getters['assets/getAssetsList']);

        return {
            selectedNode,
            assetsList,
            galleryLoading,
            assetsLoading
        };
    }
};
</script>

<style scoped>

.vh76Container{
  max-height: 76vh;
  min-height: 76vh;
  overflow: scroll;
}

</style>
